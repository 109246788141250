import nacl from 'tweetnacl';
import utils from 'tweetnacl-util';

const PUBLIC_KEY = import.meta.env.NUXT_PUBLIC_JV_FUNNEL_PUBLIC_KEY || '';
const PUBLIC_KEY_UINT8ARRAY = utils.decodeBase64(PUBLIC_KEY);

export function encrypt(data) {
  if (!PUBLIC_KEY) {
    return null;
  }
  const ephemeralKeyPair = nacl.box.keyPair();
  const nonce = nacl.randomBytes(nacl.box.nonceLength);
  const dataUInt8Array = utils.decodeUTF8(JSON.stringify(data));
  const encryptedData = nacl.box(dataUInt8Array, nonce, PUBLIC_KEY_UINT8ARRAY, ephemeralKeyPair.secretKey);

  return encodeURIComponent(
    `${utils.encodeBase64(encryptedData)}:${utils.encodeBase64(nonce)}:${utils.encodeBase64(
      ephemeralKeyPair.publicKey,
    )}`,
  );
}
