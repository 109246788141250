<script setup lang="ts">
// icons
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiTileImageSliderProps {
  campsiteName?: string | null;
  desktopUseSquareImages: boolean;
  images?: any[];
  position?: number;
  useBigImages?: boolean;
}
const props = withDefaults(defineProps<CiTileImageSliderProps>(), {
  campsiteName: null,
  images: () => [],
  position: 1,
  useBigImages: false,
});

/********************
 * COMPOSITIONS      *
 ********************/
const backendStore = useBackendStore();
const getRouteBaseName = useRouteBaseName();
const route = useRoute();
const { fixImageUrlAndCheckWebp } = useWebp();

/********************
 * REFS & VARS       *
 ********************/
const activeImage = ref(1);
const imageRefs = reactive<HTMLImageElement[]>([]);
const intersectionObserver = ref<IntersectionObserver | null>(null);
const loadedImages = shallowRef<string[]>([]);
const slider = ref<HTMLElement | null>(null);
const slides = ref<NodeList | null>(null);
const slidesCount = ref(0);

const imagesWithoutNulls = computed(() => {
  return props.images.filter(image => image['1_1'] != null && image['4_3'] != null);
});

const hasImage = computed(() => {
  return imagesWithoutNulls.value.length > 0;
});

const isSERP = computed(() => {
  const baseRouteName = getRouteBaseName(route);
  return baseRouteName
    ? [RBN_COUNTRY_SEARCH, RBN_CITY_SEARCH, RBN_FEDERAL_STATE_SEARCH, RBN_REGION_SEARCH, RBN_SEARCH].includes(
        baseRouteName,
      )
    : false;
});

/********************
 * HOOKS             *
 ********************/
onBeforeUnmount(() => {
  intersectionObserver.value?.disconnect();
});

onMounted(() => {
  slides.value = slider.value?.querySelectorAll('.slide') || null;
  slidesCount.value = slides.value?.length || 0;

  if ('IntersectionObserver' in window) {
    intersectionObserver.value = new IntersectionObserver(
      (entries) => {
        const activated = entries.reduce(function (max, entry) {
          return entry.intersectionRatio > max.intersectionRatio ? entry : max;
        });
        if (activated.intersectionRatio > 0) {
          activeImage.value = parseInt(activated.target.dataset?.idx);
        }
      },
      {
        root: slider.value,
        threshold: 0.6,
      },
    );

    slides.value?.forEach((slide) => {
      intersectionObserver.value?.observe(slide as HTMLElement);
    });
  }

  imageRefs.forEach((img, idx) => {
    if (img.complete) {
      onImgLoad(getImage(imagesWithoutNulls.value[idx]).url);
    }
  });
});

/********************
 * FUNCTIONS         *
 ********************/
function getImages(image) {
  if (!image) {
    return [];
  }

  if (props.useBigImages) {
    const width = 800;
    const height = 600;
    const { url: url1, contentType: contentType1 } = fixImageUrlAndCheckWebp(image['4_3_big'], true);
    const { url: url2, contentType: contentType2 } = fixImageUrlAndCheckWebp(image['4_3_big'], false);
    return [
      {
        url: url1,
        width,
        height,
        srcset: `${url1} ${width}w`,
        media: '(min-width: 0px)',
        type: contentType1,
      },
      {
        url: url2,
        width,
        height,
        srcset: `${url2} ${width}w`,
        media: '(min-width: 0px)',
        type: contentType2,
      },
    ];
  } else if (!props.desktopUseSquareImages) {
    const width = 500;
    const height = 375;
    const { url: url1, contentType: contentType1 } = fixImageUrlAndCheckWebp(image['4_3'], true);
    const { url: url2, contentType: contentType2 } = fixImageUrlAndCheckWebp(image['4_3'], false);
    return [
      {
        url: url1,
        width,
        height,
        srcset: `${url1} ${width}w`,
        media: '(min-width: 0px)',
        type: contentType1,
      },
      {
        url: url2,
        width,
        height,
        srcset: `${url2} ${width}w`,
        media: '(min-width: 0px)',
        type: contentType2,
      },
    ];
  } else {
    const width1 = 500;
    const height1 = 500;
    const width2 = 500;
    const height2 = 375;

    const { url: url1, contentType: contentType1 } = fixImageUrlAndCheckWebp(image['1_1'], true);
    const { url: url2, contentType: contentType2 } = fixImageUrlAndCheckWebp(image['4_3'], true);
    const { url: url3, contentType: contentType3 } = fixImageUrlAndCheckWebp(image['1_1'], false);
    const { url: url4, contentType: contentType4 } = fixImageUrlAndCheckWebp(image['4_3'], false);

    return [
      {
        url: url1,
        width: width1,
        height: height1,
        srcset: `${url1} ${width1}w`,
        media: '(min-width: 768px)',
        type: contentType1,
      },
      {
        url: url2,
        width: width2,
        height: height2,
        srcset: `${url2} ${width2}w`,
        media: '(min-width: 0px)',
        type: contentType2,
      },
      {
        url: url3,
        width: width1,
        height: height1,
        srcset: `${url3} ${width1}w`,
        media: '(min-width: 768px)',
        type: contentType3,
      },
      {
        url: url4,
        width: width2,
        height: height2,
        srcset: `${url4} ${width2}w`,
        media: '(min-width: 0px)',
        type: contentType4,
      },
    ];
  }
}

function getImage(image, force4By3 = false, webp = false) {
  let img, width, height;
  if (props.useBigImages) {
    img = image['4_3_big'];
    width = 800;
    height = 600;
  } else if (props.desktopUseSquareImages && !force4By3) {
    img = image['1_1'];
    width = 500;
    height = 500;
  } else {
    img = image['4_3'];
    width = 500;
    height = 375;
  }

  img = addWebp(fixImageUrl(backendStore.url, img), webp);

  return {
    url: img,
    width,
    height,
  };
}

function onNextImage() {
  if (activeImage.value === slidesCount.value) {
    slider.value?.scrollTo({ left: 0, behavior: 'smooth' });
  } else {
    slider.value?.scrollBy({ left: slider.value.clientWidth, behavior: 'smooth' });
  }
}

function onPreviousImage() {
  if (activeImage.value === 1) {
    slider.value?.scrollTo({ left: slider.value.clientWidth * (slidesCount.value - 1), behavior: 'smooth' });
  } else {
    slider.value?.scrollBy({ left: -slider.value.clientWidth, behavior: 'smooth' });
  }
}

function onImgLoad(image: string) {
  loadedImages.value = [...loadedImages.value, image];
}
</script>

<template>
  <div
    class="relative size-full overflow-hidden bg-black pt-[75%]"
    :class="[
      props.desktopUseSquareImages ? 'md:pt-[100%]' : 'md:pt-0',
      {
        'flex items-center justify-center bg-gray-40': !hasImage,
      },
    ]"
  >
    <!-- images -->
    <template v-if="hasImage">
      <!-- slider -->
      <div
        ref="slider"
        class="slider absolute"
      >
        <div
          v-for="(image, idx) in imagesWithoutNulls"
          :key="idx"
          class="slide"
          :class="{ 'slide--loading': !loadedImages.includes(getImage(image).url) }"
          :data-idx="idx + 1"
        >
          <picture>
            <source
              v-for="(source, idx2) of getImages(image)"
              :key="`${idx}.${idx2}`"
              :srcset="source.srcset"
              :media="source.media"
              :type="source.type"
            />
            <img
              :ref="el => (imageRefs[idx] = el as HTMLElement)"
              :key="idx"
              :draggable="false"
              :src="getImage(image).url"
              :width="getImage(image).width"
              :height="getImage(image).height"
              class="block h-auto w-full"
              :loading="isSERP && idx === 0 && props.position === 0 ? 'eager' : 'lazy'"
              :fetchpriority="isSERP && idx === 0 && props.position === 0 ? 'high' : 'auto'"
              decoding="async"
              alt="image"
              @load="onImgLoad(getImage(image).url)"
            />
          </picture>
        </div>
      </div>

      <!-- image navigation -->
      <div
        v-if="imagesWithoutNulls.length > 1"
        class="absolute left-0 top-0 flex h-full items-center px-2 hover:cursor-pointer md:px-0"
        @click.stop.prevent="onPreviousImage"
      >
        <CiAwesomeIcon
          :icon="faChevronLeft"
          class="fill-white"
          ratio="1"
        />
      </div>
      <div
        v-if="imagesWithoutNulls.length > 1"
        class="absolute right-0 top-0 flex h-full items-center px-2 hover:cursor-pointer md:px-0"
        @click.stop.prevent="onNextImage"
      >
        <CiAwesomeIcon
          :icon="faChevronRight"
          class="fill-white"
          ratio="1"
        />
      </div>

      <!-- image button gradient -->
      <div
        v-if="imagesWithoutNulls.length > 1"
        class="image-slider__image-gradient absolute bottom-0 left-0 h-9 w-full"
      >
        <!-- image dots -->
        <div class="absolute bottom-[5px] flex w-full items-center justify-center">
          <span
            v-for="(_img, idx) in imagesWithoutNulls"
            :key="idx"
            class="mr-1 rounded-full bg-gray-30 indent-96 last:mr-0"
            :class="[activeImage === idx + 1 ? 'size-2' : 'size-[5px]']"
          >{{ idx }} {{ activeImage }}</span>
        </div>
      </div>
    </template>

    <!-- fallback image -->
    <img
      v-if="!hasImage"
      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      alt=""
      src="~/assets/svg/ci-bildmarke-white.svg"
    />
  </div>
</template>

<style lang="scss" scoped>
.slider {
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  left: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  top: 0;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.slide {
  flex: 1 0 auto;
  position: relative;
  scroll-snap-align: center;
  width: 100%;
}

.image-slider__image-gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}
</style>
