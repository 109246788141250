<script setup lang="ts">
// icons
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

/********************
 * PROPS & EMITS     *
 ********************/
const props = defineProps({
  visibleCount: { type: Number, required: true },
  count: { type: Number, required: true },
});

const emit = defineEmits<{
  'show-more': [];
}>();
</script>

<template>
  <div
    v-if="props.visibleCount < props.count"
    class="flex cursor-pointer items-center justify-center py-2 text-sm text-primary"
    @click="emit('show-more')"
  >
    <span>{{ $pgettext('Campsite Tile load more flex availabilities', 'Show more') }}</span>
    <CiAwesomeIcon
      :icon="faChevronDown"
      class="ml-2 fill-primary"
      ratio="0.6"
    />
  </div>
</template>

<style></style>
